import { Heading, Stack } from "@chakra-ui/layout";
import React, { useState } from "react";
import Header from "../components/Header";
import bgImage from "../assets/welcomeImages/bg2.png";
import { Input } from "@chakra-ui/input";
import { Button } from "@chakra-ui/button";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

function BcomOnBoarding() {
  const history = useHistory();
  const [bcomHotelId, setbcomHotelId] = useState("");

  const onboardProperty = () => {
    history.push({
      pathname: "/bcom-property",
      state: { detail: bcomHotelId },
    });
  };
  return (
    <div>
      <Header />
      <Stack
        justifyContent="center"
        alignItems="center"
        bg={`url(${bgImage})`}
        backgroundPosition="center"
        backgroundSize="contain"
        h={{ base: "calc(100vh - 4rem)", md: "calc(100vh - 5rem)" }}
      >
        <Stack
          justifyContent="space-between"
          alignItems="center"
          bgColor="white"
          p="5"
          spacing="5"
          borderRadius="lg"
          boxShadow="md"
          maxW={{ base: "100%", md: "24rem" }}
          h={{ base: "inherit", md: "fit-content" }}
        >
          <Heading as="h2" fontSize="md" color="blackAlpha.800">
            Enter Booking.com property id
          </Heading>
          <Input
            variant="outline"
            bgColor="white"
            id="email"
            focusBorderColor="#1ab394"
            placeholder="Enter the property id"
            value={bcomHotelId}
            onChange={(e) => setbcomHotelId(e.target.value)}
          />
          <Button
            rightIcon={<ArrowForwardIcon />}
            mt={4}
            bg="linear-gradient(150deg, #1ab394e6, #1a91aee6)"
            _hover={{
              bg: "linear-gradient(150deg, #1ab394, #1a91ae)",
            }}
            boxShadow="lg"
            style={{
              zIndex: "1031",
              color: "white",
              fontWeight: "600",
            }}
            width="100%"
            type="submit"
            onClick={onboardProperty}
          >
            Onboard
          </Button>
          {/* {signFlow === 0 && (
            <Stack width="100%" spacing="7">
              <Heading as="h2" fontSize="md" color="blackAlpha.800">
                Sign in or create an account
              </Heading>
              <Formik
                initialValues={{ email: "" }}
                onSubmit={(values, actions) => {
                  setUserEmail(values.email);
                  EmailCheck(values.email);
                }}
              >
                {(props) => (
                  <Form>
                    <Field name="email" validate={validateName}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                        >
                          <FormLabel
                            htmlFor="email"
                            color="blackAlpha.800"
                            fontSize="sm"
                          >
                            Email address?
                          </FormLabel>
                          <Input
                            {...field}
                            variant="outline"
                            bgColor="white"
                            id="email"
                            focusBorderColor="#1ab394"
                            placeholder="enter the email"
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    {!errorArray.includes(errorMessage) && (
                      <Text fontSize="xs" color="red.500">
                        {errorMessage}
                      </Text>
                    )}
                    <Button
                      rightIcon={<ArrowForwardIcon />}
                      mt={4}
                      bg="linear-gradient(150deg, #1ab394e6, #1a91aee6)"
                      _hover={{
                        bg: "linear-gradient(150deg, #1ab394, #1a91ae)",
                      }}
                      boxShadow="lg"
                      style={{
                        zIndex: "1031",
                        color: "white",
                        fontWeight: "600",
                      }}
                      width="100%"
                      isLoading={props.isSubmitting}
                      type="submit"
                    >
                      Continue with email
                    </Button>
                    <div
                      style={{
                        display: "block",
                        height: "1px",
                        border: "0",
                        borderTop: "1px solid #ccc",
                        margin: "1em 0",
                        padding: "0",
                      }}
                    ></div>
                    <Button
                      leftIcon={
                        <img
                          src="https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/booking.png"
                          style={{
                            height: "1.5rem",
                            borderRadius: "100%",
                          }}
                        />
                      }
                      mt={4}
                      bg="linear-gradient(150deg, #1ab394e6, #1a91aee6)"
                      _hover={{
                        bg: "linear-gradient(150deg, #1ab394, #1a91ae)",
                      }}
                      boxShadow="lg"
                      style={{
                        zIndex: "1031",
                        color: "white",
                        fontWeight: "600",
                      }}
                      width="100%"
                      isLoading={props.isSubmitting}
                      onClick={(e) => {
                        sessionStorage.setItem("Bcom", "true");
                        props.handleSubmit();
                      }}
                    >
                      Sign up with Booking.com
                    </Button>
                  </Form>
                )}
              </Formik>
            </Stack>
          )} */}
        </Stack>
      </Stack>
    </div>
  );
}

export default BcomOnBoarding;
