import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import axios from "axios";
import * as Constants from "../utils/constants";
import { useLocation, Redirect } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ReactImageGallery from "react-image-gallery";
import ht1 from "../assets/welcomeImages/h1.jpg";
import vc1 from "../assets/welcomeImages/vacation-rentals.jpg";
import hostel from "../assets/welcomeImages/youth-hostel.jpg";
import { Heading, Stack, Text } from "@chakra-ui/layout";
import { CloseIcon, CheckIcon } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";
import { useStateValue } from "../context/StateProvider";
import { useHistory } from "react-router";

function AirbnbProperty() {
  const location = useLocation();
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [isError, setisError] = useState(false);
  const history = useHistory();

  const images = [
    {
      original: vc1,
    },
    {
      original: ht1,
    },
    {
      original: hostel,
    },
  ];
  useEffect(() => {
    // debugger;
    // setloader(true);
    const payload = {
      email: location?.state?.detail?.userEmail,
      channelName: "airbnb",
      channelHotelId: "",
      hostId: location?.state?.detail?.airbnbHostId,
      listingIds: location?.state?.detail?.listing,
      authCode: location?.state?.detail?.authCode,
    };

    console.log(payload);
    axios
      .post(`${Constants.baseUrl}/core/api/v1/onboard/ota-hotel`, payload)
      .then((res) => {
        seterrorMsg(res.data);
        setisError(false);
        setloader(false);
        sessionStorage.setItem("Airbnb", "false");
      })
      .catch((err) => {
        seterrorMsg(err.response.data);
        sessionStorage.setItem("Airbnb", "false");
        setisError(true);
        setloader(false);
      });
  }, []);

  const removeUser = () => {
    localStorage.clear();
    history.push("/");
    window.location.reload(false);
  };

  const [{ userEmail, currentStep }, dispatch] = useStateValue();

  return loader === false ? (
    <div>
      <Header
        loggedInUser={location?.state?.detail?.userEmail}
        SignOut={removeUser}
      />
      <div
        className="d-flex"
        style={{
          display: "flex",
          width: "100%",
          padding: "1rem",
        }}
      >
        <Stack
          className="d-flex flex-column justify-content-center"
          spacing="10"
          style={{ width: "100%" }}
        >
          <Stack spacing="3" className="d-flex">
            <div style={{ display: "flex" }}>
              {isError ? (
                <Icon
                  as={CloseIcon}
                  w={6}
                  h={6}
                  style={{
                    marginTop: "1rem",
                    marginRight: "1rem",
                    color: "red",
                  }}
                />
              ) : (
                <Icon
                  as={CheckIcon}
                  w={6}
                  h={6}
                  style={{
                    marginTop: "1rem",
                    marginRight: "1rem",
                    color: "green",
                  }}
                />
              )}
              <Text fontSize="xl" color="blackAlpha.600">
                {errorMsg}
              </Text>
            </div>
          </Stack>
        </Stack>
        <div style={{ width: "100%" }}>
          {" "}
          <ReactImageGallery
            md={4}
            infinite={true}
            autoPlay={true}
            slideInterval={2000}
            lazyLoad={true}
            showNav={false}
            showThumbnails={false}
            showFullscreenButton={false}
            showPlayButton={false}
            items={images}
            showBullets={true}
          />
        </div>
      </div>
    </div>
  ) : (
    <Skeleton count={20} />
  );
}

export default AirbnbProperty;
