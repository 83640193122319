export const types = {
  HOTEL: "rooms",
  VACATION_RENTAL: "rental units",
  HOSTEL: "beds",
  APARTMENTS: "units",
};
export const propertyDetailsTips = [
  "This is the public name of your property.",
  "Enter your mobile number. Only numbers are allowed",
  "Select your property type",
  " Only letters and numbers are allowed.",
  "Write a captivating description to get the attention of the traveller. Explain why your property stands out!",
];

export const errorArray = ["", undefined, "undefined", null, 0, NaN];
export const errorArrayWithoutZero = ["", undefined, "undefined", null, NaN];

export const amenitiesList = [
  { name: "Internet/Wi-Fi", state: false },
  { name: "On Premise Parking", state: false },
  { name: "24-hour front desk service", state: false },
  { name: "Swimming pool", state: false },
  { name: "Bar", state: false },
  { name: "Restaurant", state: false },
  { name: "Coffee/tea in lobby", state: false },
  { name: "Vending Machine", state: false },
  { name: "Room Service", state: false },
  { name: "Spa", state: false },
  { name: "Sauna", state: false },
  { name: "Hot Tub/Jacuzzi", state: false },
  { name: "Gym", state: false },
  { name: "Breakfast", state: false },
  { name: "Shuttle/Cab Service", state: false },
  { name: "Currency Exchange", state: false },
  { name: "Dry Cleaning", state: false },
  { name: "Laundry", state: false },
  { name: "Meeting/Banquet Facilities", state: false },
  { name: "ATM On Site", state: false },
  { name: "24 Hours Power Backup", state: false },
  { name: "Luggage storage", state: false },
  { name: "Safety deposit boxes", state: false },
  { name: "Elevator", state: false },
  { name: "Concierge", state: false },
  { name: "Airport transfer", state: false },
  { name: "Rental car", state: false },
  { name: "Valet parking", state: false },
  { name: "Tours", state: false },
];

export const typeMap = (type) => {
  const propTypeMap =
    type === "VACATION_RENTAL"
      ? [
          "Property",
          "Location",
          "Amenities",
          "Photos",
          "Room details",
          "Cancellation policy",
        ]
      : type === "HOSTEL"
      ? [
          "Property",
          "Location",
          "Amenities",
          "Photos",
          "Room details",
          "Cancellation policy",
        ]
      : [
          "Property",
          "Location",
          "Amenities",
          "Photos",
          "Room details",
          "Cancellation policy",
        ];

  return propTypeMap;
};

export const roomTypeDetailsTips = (propertyType) => {
  const tip =
    propertyType === "VACATION_RENTAL"
      ? [
          "What is the default number of adults for this rental?",
          "What is the default adult price for this rental?",
        ]
      : [
          `Enter the name of this ${
            propertyType === "HOSTEL" ? "bed" : "room"
          } type`,
          `Enter the number of ${
            propertyType === "HOSTEL" ? "beds" : "rooms"
          } in this ${propertyType === "HOSTEL" ? "bed" : "room"} type`,
          `What is the default number of adults for this ${
            propertyType === "HOSTEL" ? "bed" : "room"
          } type?`,
          `What is the maximum adult occupancy for this ${
            propertyType === "HOSTEL" ? "bed" : "room"
          } type?`,
          `What is the maximum children occupancy for this ${
            propertyType === "HOSTEL" ? "bed" : "room"
          } type?`,
          `What is the default adult price for this ${
            propertyType === "HOSTEL" ? "bed" : "room"
          } type?`,
          `What is the default min price for this ${
            propertyType === "HOSTEL" ? "bed" : "room"
          } type?`,
          "Price for each extra adult exceeding the default occupancy",
          "Price for a child",
          `Enter the ${propertyType === "HOSTEL" ? "bed" : "room"} IDs of the ${
            propertyType === "HOSTEL" ? "bed" : "room"
          }. Avoid blank spaces and any special character`,
        ];

  return tip;
};

export const roomAmenitiesList = [
  { name: "Childrens cribs", state: false },
  { name: "Clothes rack", state: false },
  { name: "Drying rack for clothing", state: false },
  { name: "Fold-up bed", state: false },
  { name: "Sofa bed", state: false },
  { name: "Trash cans", state: false },
  { name: "Air conditioning", state: false },
  { name: "Dryer", state: false },
  { name: "Wardrobe/Closet", state: false },
  { name: "Carpeted", state: false },
  { name: "Walk-in closet", state: false },
  { name: "Extra long beds (> 6.5 ft)", state: false },
  { name: "Fan", state: false },
  { name: "Heating", state: false },
  { name: "Interconnecting room(s) available", state: false },
  { name: "Iron", state: false },
  { name: "Hot tub", state: false },
  { name: "Mosquito net", state: false },
  { name: "Private entrance", state: false },
  { name: "Safe", state: false },
  { name: "Sofa", state: false },
  { name: "Soundproof", state: false },
  { name: "Tile/Marble floor", state: false },
  { name: "Washing machine", state: false },
  { name: "Hardwood/Parquet floors", state: false },
  { name: "Desk", state: false },
  { name: "Private bathroom", state: false },
  { name: "Shared bathroom", state: false },
  { name: "Toilet paper", state: false },
  { name: "Toiletries", state: false },
  { name: "Bathrobe", state: false },
  { name: "Shower", state: false },
  { name: "Bottle of Water", state: false },
  { name: "Toaster", state: false },
  { name: "Dishwasher", state: false },
  { name: "Electric kettle", state: false },
  { name: "Kitchen", state: false },
  { name: "Kitchenware", state: false },
  { name: "Microwave", state: false },
  { name: "Refrigerator", state: false },
  { name: "Tea/Coffee machine", state: false },
  { name: "Alarm clock", state: false },
  { name: "Wake-up service", state: false },
  { name: "Linens", state: false },
  { name: "Towels", state: false },
  { name: "Balcony", state: false },
  { name: "Patio", state: false },
  { name: "Pool view", state: false },
  { name: "Sea view", state: false },
  { name: "TV", state: false },
  { name: "Iron Board", state: false },
  { name: "Cloth Hangars", state: false },
];

export const cancellationPolicyTips = [
  "This is the cancellation policy of your property.",
  "Enter the name of your cancellation policy.",
  "Enter the number of days before stay, guest can cancel booking. Please enter the value greater than 0",
  "Enter the refund amount % before stay, guest will get refund. Please enter the  value greater than 0 and less than or equal to 100",
  "Enter the number of hours before stay, guest can cancel booking. Please enter the value greater than 0",
  "Enter the refund amount % poststay, guest will get refund. Please enter the  value greater than 0 and less than or equal to 100",
  "Enter the number of hours poststay, guest can cancel booking. Please enter the value greater than 0",
  "Enter few lines describing cancellation policy",
];

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
