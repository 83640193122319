export const initialState = {
  hotelId: localStorage.getItem("hotelId")
    ? Number(localStorage.getItem("hotelId"))
    : null,
  currentStep: localStorage.getItem("currentStep")
    ? Number(localStorage.getItem("currentStep"))
    : 0,
  propertyType: "",
  onboardingProgress: localStorage.getItem("onboardingProgress")
    ? JSON.parse(localStorage.getItem("onboardingProgress"))
    : false,
  userEmail: localStorage.getItem("userEmail")
    ? localStorage.getItem("userEmail")
    : null,
  userName: localStorage.getItem("userName")
    ? JSON.parse(localStorage.getItem("userName"))
    : null,
  propertyDetails: localStorage.getItem("propertyDetails")
    ? JSON.parse(localStorage.getItem("propertyDetails"))
    : null,
  propertyAddress: localStorage.getItem("propertyAddress")
    ? JSON.parse(localStorage.getItem("propertyAddress"))
    : null,
  propertyAmenities: localStorage.getItem("propertyAmenities")
    ? JSON.parse(localStorage.getItem("propertyAmenities"))
    : null,
  rentalDetails: localStorage.getItem("rentalDetails")
    ? JSON.parse(localStorage.getItem("rentalDetails"))
    : null,
  addRoomTypeActive: localStorage.getItem("addRoomTypeActive")
    ? JSON.parse(localStorage.getItem("addRoomTypeActive"))
    : false,
  editRoomTypeActive: localStorage.getItem("editRoomTypeActive")
    ? JSON.parse(localStorage.getItem("editRoomTypeActive"))
    : false,
  roomTypeId: localStorage.getItem("roomTypeId")
    ? Number(localStorage.getItem("roomTypeId"))
    : null,

  roomTypes: localStorage.getItem("roomTypes")
    ? JSON.parse(localStorage.getItem("roomTypes"))
    : null,
  policyDetails: localStorage.getItem("policyDetails")
    ? JSON.parse(localStorage.getItem("policyDetails"))
    : null,
  stepProgress: localStorage.getItem("stepProgress")
    ? JSON.parse(localStorage.getItem("stepProgress"))
    : null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_HOTEL_ID":
      localStorage.setItem("hotelId", action.hotelId);
      return {
        ...state,
        hotelId: action.hotelId,
      };
    case "SET_CURRENT_STEP":
      localStorage.setItem("currentStep", action.currentStep);
      return {
        ...state,
        currentStep: action.currentStep,
      };
    case "SET_ONBOARDING_PROGRESS":
      localStorage.setItem("onboardingProgress", action.onboardingProgress);
      return {
        ...state,
        onboardingProgress: action.onboardingProgress,
      };
    case "SET_PROPERTY_TYPE":
      return {
        ...state,
        propertyType: action.propertyType,
      };
    case "SET_USER_EMAIL":
      localStorage.setItem("userEmail", action.userEmail);
      return {
        ...state,
        userEmail: action.userEmail,
      };
    case "SET_USER_NAME":
      localStorage.setItem("userName", action.userName);
      return {
        ...state,
        userName: action.userName,
      };
    case "SET_PROPERTY_DETAILS":
      localStorage.setItem(
        "propertyDetails",
        JSON.stringify(action.propertyDetails)
      );
      return {
        ...state,
        propertyDetails: action.propertyDetails,
      };
    case "SET_PROPERTY_ADDRESS":
      localStorage.setItem(
        "propertyAddress",
        JSON.stringify(action.propertyAddress)
      );
      return {
        ...state,
        propertyAddress: action.propertyAddress,
      };
    case "SET_PROPERTY_AMENITIES":
      localStorage.setItem(
        "propertyAmenities",
        JSON.stringify(action.propertyAmenities)
      );
      return {
        ...state,
        propertyAmenities: action.propertyAmenities,
      };
    case "SET_RENTALS_DETAILS":
      localStorage.setItem(
        "rentalDetails",
        JSON.stringify(action.rentalDetails)
      );
      return {
        ...state,
        rentalDetails: action.rentalDetails,
      };
    case "SET_ADD_ROOM_TYPE_ACTIVE":
      localStorage.setItem("addRoomTypeActive", action.addRoomTypeActive);
      return {
        ...state,
        addRoomTypeActive: action.addRoomTypeActive,
      };
    case "SET_EDIT_ROOM_TYPE_ACTIVE":
      localStorage.setItem("editRoomTypeActive", action.editRoomTypeActive);
      return {
        ...state,
        editRoomTypeActive: action.editRoomTypeActive,
      };
    case "SET_ROOM_TYPE_ID":
      localStorage.setItem("roomTypeId", action.roomTypeId);
      return {
        ...state,
        roomTypeId: action.roomTypeId,
      };
    case "SET_ROOM_TYPES":
      localStorage.setItem("roomTypes", JSON.stringify(action.roomTypes));
      return {
        ...state,
        roomTypes: action.roomTypes,
      };
    case "SET_POLICY_DETAILS":
      localStorage.setItem(
        "policyDetails",
        JSON.stringify(action.policyDetails)
      );
      return {
        ...state,
        policyDetails: action.policyDetails,
      };
    case "SET_STEP_PROGRESS":
      let tempStepProgress = {
        ...state.stepProgress,
      };
      tempStepProgress[action.stepName] = action.stepProgress;
      localStorage.setItem("stepProgress", JSON.stringify(tempStepProgress));
      return {
        ...state,
        stepProgress: tempStepProgress,
      };
    case "RESET_PROGRESS":
      let resetProgress = {
        Property: "NOT_STARTED",
        Location: "NOT_STARTED",
        Amenities: "NOT_STARTED",
        Photos: "NOT_STARTED",
        "Room details": "NOT_STARTED",
        "Cancellation policy": "NOT_STARTED",
      };
      localStorage.setItem("stepProgress", JSON.stringify(resetProgress));
      return {
        ...state,
        stepProgress: resetProgress,
      };
    default:
      return state;
  }
};

export default reducer;
