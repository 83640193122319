import { ChakraProvider } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reducer, { initialState } from "./context/reducer.js";
import { StateProvider } from "./context/StateProvider";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const envType = process.env.REACT_APP_ENV;

//tracing UI errors using Sentry
if (envType === "PROD" || envType === "BETA") {
    Sentry.init({
        dsn: "https://ab7a17d7397b4c2386e5db8423c217a8@o1173407.ingest.sentry.io/6369720",
        integrations: [new BrowserTracing()],
        release: "1.0",
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
      <StateProvider initialState={initialState} reducer={reducer}>
        <App />
      </StateProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
