import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import axios from "axios";
import * as Constants from "../utils/constants";
import { useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function BcomProperty(props) {
  const location = useLocation();
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [isError, setisError] = useState(false);

  useEffect(() => {
    setloader(true);
    axios
      .post(`${Constants.baseUrl}/core/api/v1/onboard/ota-hotel`, {
        email: localStorage.getItem("userEmail"),
        channelName: "booking",
        channelHotelId: location.state.detail,
        hostId: "",
        listingIds: [],
      })
      .then((res) => {
        console.log("res", res);
        setisError(false);
        setloader(false);
        sessionStorage.setItem("Bcom", "false");
      })
      .catch((err) => {
        console.error("Invalid referral code");
        seterrorMsg(err.response.data);
        sessionStorage.setItem("Bcom", "false");
        setisError(true);
        setloader(false);
      });
  }, []);

  return loader === false ? (
    <div>
      <Header />
      <h1
        style={{
          color: `${isError === true ? "red" : "green"}`,
          marginTop: "3rem",
          marginLeft: "1rem",
          marginRight: "1rem",
          fontWeight: "bold",
        }}
      >
        {errorMsg}
      </h1>
    </div>
  ) : (
    <Skeleton count={20} />
  );
}

export default BcomProperty;
