import { Heading, Stack } from "@chakra-ui/layout";
import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import bgImage from "../assets/welcomeImages/bg2.png";
import { Input } from "@chakra-ui/input";
import { Button } from "@chakra-ui/button";
import { ArrowForwardIcon, QuestionIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router";
import axios from "axios";
import * as Constants from "../utils/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, Image, Text, Flex } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import hostId from "./AirbnbImages/HostId.png";
import listingId from "./AirbnbImages/Listing.png";
import { Spinner } from "@chakra-ui/react";
function AirbnbOnBoarding({
  setSignFlow,
  payload,
  selectedCards,
  setSelectedCards,
}) {
  const history = useHistory();
  const [airbnbHostId, setairbnbHostId] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [authFocus, setauthFocus] = useState(false);
  const [showAuthCode, setshowAuthCode] = useState(false);
  const [authCode, setauthCode] = useState("");
  const [listing, setlisting] = useState("");
  const [airbnbModal, setairbnbModal] = useState(false);
  const openModal = () => setairbnbModal(true);
  const closeModal = () => setairbnbModal(false);
  const [modalType, setmodalType] = useState("");
  // const [payload, setpayload] = useState([
  //   {
  //     hostId: "342377673",
  //     listingId: "142377",
  //     listingName: "King Room",
  //     mapped: false,
  //     imageUrl: "https://picsum.photos/200/300?random=1",
  //   },
  //   {
  //     hostId: "341277675",
  //     listingId: "153499",
  //     listingName: "Queen Room",
  //     mapped: true,
  //     imageUrl: "https://picsum.photos/200/300?random=2",
  //   },
  // ]);
  const [cardsPerRow, setCardsPerRow] = useState(3);
  const [loader, setloader] = useState(false);

  const onboardProperty = () => {
    setSignFlow(7);
    // history.push({
    //   pathname: "/airbnb-property",
    //   state: {
    //     detail: {
    //       authCode: showAuthCode ? authCode : "",
    //       airbnbHostId: airbnbHostId,
    //       listing: selectedCards,
    //     },
    //   },
    // });
  };

  // useEffect(() => {
  //   if (
  //     showAuthCode &&
  //     authFocus === false &&
  //     airbnbHostId.length > 0 &&
  //     authCode.length > 0
  //   ) {
  //     setloader(true);
  //     axios
  //       .get(
  //         `${Constants.baseUrl}/core/api/v1/onboard/fetch-host-listings?hostId=${airbnbHostId}&authCode=${authCode}`
  //       )
  //       .then((res) => {
  //         setpayload(res.data);
  //         toast.error(res.data.length <= 0 && "No listings found");
  //         setloader(false);
  //       })
  //       .catch((err) => {
  //         toast.error(err.response.data);
  //         setloader(false);
  //         setpayload([]);
  //         toast.error(err.response.data.length <= 0 && "No listings found");
  //       });
  //   }
  // }, [authCode, authFocus]);

  // useEffect(() => {
  //   if (isFocused === false && airbnbHostId.length > 0) {
  //     setloader(true);
  //     axios
  //       .get(
  //         `${Constants.baseUrl}/core/api/v1/onboard/fetch-host-listings?hostId=${airbnbHostId}`
  //       )
  //       .then((res) => {
  //         setpayload(res.data);
  //         setshowAuthCode(false);
  //         toast.error(res.data.length <= 0 && "No listings found");
  //         setloader(false);
  //       })
  //       .catch((err) => {
  //         toast.error(err.response.data);
  //         setshowAuthCode(true);
  //         setloader(false);
  //         setpayload([]);
  //       });
  //   }
  // }, [airbnbHostId, isFocused]);

  ////////////////// const [selectedCards, setSelectedCards] = useState([]);

  const handleCardSelect = (listingId) => {
    setSelectedCards((prevSelected) =>
      prevSelected.includes(listingId)
        ? prevSelected.filter((id) => id !== listingId)
        : [...prevSelected, listingId]
    );
  };

  const toggleReadMore = (listingId) => {
    setSelectedCards((prevSelected) =>
      prevSelected.includes(listingId)
        ? prevSelected.filter((id) => id !== listingId)
        : [...prevSelected, listingId]
    );
  };

  const isCardExpanded = (listingId) => {
    return selectedCards.includes(listingId);
  };

  // console.log("selectedCards", selectedCards);

  return (
    <div style={{ height: "100vh" }}>
      <ToastContainer />
      {/* <Header /> */}

      <Stack
        alignItems="flex-start"
        bgColor="white"
        p="5"
        spacing="5"
        borderRadius="lg"
        style={{
          width: "100vw",
          // height: "100vh",
          marginTop: "1rem",
        }}
      >
        {/* <div>
          <Input
            variant="outline"
            bgColor="white"
            id="email"
            style={{ width: "21rem", marginLeft: "1rem" }}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            focusBorderColor="#1ab394"
            placeholder="Enter airbnb host id"
            value={airbnbHostId}
            onChange={(e) => setairbnbHostId(e.target.value)}
          />
          <QuestionIcon
            onClick={() => {
              setmodalType("host");
              openModal();
            }}
            style={{ marginLeft: "8px" }}
          />
        </div> */}

        {/* {showAuthCode && (
          <div>
            <Input
              variant="outline"
              bgColor="white"
              id="Airbnb Auth Code"
              focusBorderColor="#1ab394"
              style={{ width: "21rem", marginTop: "1rem", marginLeft: "1rem" }}
              placeholder="Airbnb Auth Code"
              value={authCode}
              onFocus={() => {
                setauthFocus(true);
              }}
              onBlur={() => {
                setauthFocus(false);
              }}
              onChange={(e) => setauthCode(e.target.value)}
            />
            <QuestionIcon
              onClick={() => {
                setmodalType("auth");
                openModal();
              }}
              style={{ marginLeft: "8px" }}
            />
          </div>
        )} */}

        {payload.length > 0 ? (
          <p style={{ marginTop: "1rem", marginLeft: "1rem" }}>
            To onboard Airbnb listings on Stayflexi, you can select multiple
            properties in one go.
          </p>
        ) : (
          <p style={{ marginTop: "1rem", marginLeft: "1rem" }}>
            No listing available
          </p>
        )}
        <Flex justifyContent="start" flexWrap="wrap" p={4}>
          {payload
            ?.sort((a, b) => a.mapped - b.mapped)
            ?.map((item, index) => (
              <Box
                key={item.listingId}
                w="310px"
                flexBasis="310px"
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                m={4}
                style={{ marginLeft: "0px" }}
                boxShadow="base"
                bg={
                  item?.mapped
                    ? "white"
                    : selectedCards.includes(item.listingId)
                    ? "gray.100"
                    : "white"
                }
                cursor={item?.mapped ? "default" : "pointer"}
                onClick={() =>
                  item?.mapped === false && handleCardSelect(item.listingId)
                }
                h="auto"
              >
                <Image
                  height="200px"
                  width="100%"
                  src={item.imageUrl}
                  alt={item.listingName}
                />

                <Box p="6">
                  <p className="text-muted" style={{ color: "#1BC5BD" }}>
                    {item?.mapped ? "Already onboarded" : ""}
                  </p>
                  <Text
                    fontWeight="bold"
                    fontSize="xl"
                    noOfLines={isCardExpanded(item.listingId) ? 0 : 2}
                  >
                    {item.listingName}
                  </Text>
                  {item.listingName.split("\n").length > 2 && (
                    <Button
                      size="sm"
                      colorScheme="teal"
                      mt={2}
                      onClick={() => toggleReadMore(item.listingId)}
                    >
                      {isCardExpanded(item.listingId)
                        ? "Read Less"
                        : "Read More"}
                    </Button>
                  )}
                </Box>
              </Box>
            ))}
        </Flex>
        <Button
          rightIcon={<ArrowForwardIcon />}
          mt={2}
          bg="linear-gradient(150deg, #1ab394e6, #1a91aee6)"
          _hover={{
            bg: "linear-gradient(150deg, #1ab394, #1a91ae)",
          }}
          boxShadow="lg"
          disabled={payload.length <= 0 || loader || selectedCards.length <= 0}
          style={{
            zIndex: "1031",
            color: "white",
            fontWeight: "600",
            minHeight: "3rem",
            marginLeft: "1rem",
          }}
          width="22rem"
          type="submit"
          onClick={onboardProperty}
        >
          {loader ? <Spinner /> : "Next"}
        </Button>
      </Stack>

      {/* <Modal
        isOpen={airbnbModal}
        onClose={closeModal}
        size="full"
        style={{ height: "20rem !important" }}
      >
        <ModalOverlay style={{ height: "20rem !important" }} />
        <ModalContent width="1000px" height="500px">
          <ModalHeader>
            {modalType === "host"
              ? "Host Id"
              : modalType === "listings"
              ? "Listing Id"
              : "Auth Code"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modalType === "host" && <img src={hostId} alt="loader" />}
            {modalType === "auth" && (
              <p>
                For auth code contact{" "}
                <a
                  style={{ color: "blue", textDecoration: "underline" }}
                  href="support@stayflexi.com"
                >
                  support@stayflexi.com
                </a>
              </p>
            )}
            {modalType === "listings" && <img src={listingId} alt="loader" />}
          </ModalBody>
        </ModalContent>
      </Modal> */}
    </div>
  );
}

export default AirbnbOnBoarding;
