/* eslint-disable import/no-anonymous-default-export */
import { Button } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { Stack } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import * as Constants from "../utils/constants";

// const browserURL = window.location.href;
// const isMHO = browserURL.includes("mhohotels");

export default ({ loggedInUser, SignOut }) => {
  const [brandingData, setbrandingData] = useState();
  useEffect(() => {
    axios
      .get(
        `${Constants.baseUrl}/user/groupBranding` +
          "?hostUrl=" +
          `${window.location.hostname}`
      )
      .then((resp) => setbrandingData(resp.data))
      .catch((err) => {
        throw new Error(err);
      });
  }, []);
  if (!loggedInUser) {
    return null;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      px="4"
      bg="white"
      height={{ base: "16", md: "20" }}
      boxShadow="base"
    >
      <Link to="/">
        <Image
          width="48"
          objectFit="cover"
          src={brandingData?.logoUrl}
          alt="Stayflexi"
        />
      </Link>
      {loggedInUser && (
        <Link to="/">
          <Button
            bg="linear-gradient(150deg, #1ab394e6, #1a91aee6)"
            colorScheme="teal"
            variant="solid"
            size="sm"
            onClick={SignOut}
            _hover={{
              background: "linear-gradient(150deg, #1ab394, #1a91ae)",
            }}
          >
            {"Sign out"}
          </Button>
        </Link>
      )}
    </Stack>
  );
};
