import { getCountriesForTimezone } from "countries-and-timezones";
import { lazy, Suspense, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
  Link,
} from "react-router-dom";
import "./App.css";
import rippleLoader from "./assets/loader.svg";
import { useStateValue } from "./context/StateProvider";
import AirbnbOnBoarding from "./pages/AirbnbOnBoarding";
import AirbnbProperty from "./pages/AirbnbProperty";
import BcomOnBoarding from "./pages/BcomOnBoarding";
import BcomProperty from "./pages/BcomProperty";
import { errorArray } from "./utils/common";
const Header = lazy(() => import("./components/Header"));
const Signup = lazy(() => import("./pages/Auth"));
const WelcomeScreen = lazy(() => import("./pages/WelcomeScreen"));
const InitialWelcomePage = lazy(() => import("./pages/InitialWelcomePage"));

function App() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [utmSource] = useState(
    params["utm_source"] ? params["utm_source"] : ""
  );
  const countryId = getCountriesForTimezone(
    Intl.DateTimeFormat().resolvedOptions()?.timeZone
  )[0]?.id;
  if (utmSource) {
    localStorage.setItem("utm_source", utmSource);
  } else {
    localStorage.setItem("utm_source", "SELF_LINK");
  }

  if (countryId) {
    localStorage.setItem("country_id", countryId);
  } else {
    localStorage.setItem("country_id", "US");
  }

  const [{ userEmail, currentStep }, dispatch] = useStateValue();
  const history = useHistory();
  const [loading] = useState(false);

  const removeUser = () => {
    localStorage.clear();
    <Redirect to="/" />;
    window.location.reload(false);
    // dispatch({
    //   type: "SET_USER_EMAIL",
    //   userEmail: '',
    // });
    // if (currentStep === 11) {
    //   dispatch({
    //     type: "SET_CURRENT_STEP",
    //     currentStep: 1,
    //   });
    // }
  };

  if (loading)
    return (
      <div
        className="w-100 d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <img src={rippleLoader} alt="loader" />
      </div>
    );
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="App d-flex flex-column">
        <Router>
          <Switch>
            {
              <Route path="/signup">
                <Header />
                <Signup />
              </Route>
            }

            <Route exact path="/bcom-onboarding">
              <BcomOnBoarding />
            </Route>

            <Route exact path="/airbnb-onboarding">
              <AirbnbOnBoarding />
            </Route>

            <Route exact path="/bcom-property">
              <BcomProperty />
            </Route>
            <Route exact path="/airbnb-property">
              <AirbnbProperty />
            </Route>

            {!errorArray.includes(userEmail) ? (
              <Route exact path="/onboarding">
                <Header loggedInUser={userEmail} SignOut={removeUser} />
                <WelcomeScreen
                  name={`${
                    userEmail.displayName !== null ? userEmail.displayName : ""
                  }`}
                  user={userEmail}
                />
              </Route>
            ) : (
              <Route exact path="/">
                <InitialWelcomePage />
              </Route>
            )}

            {!errorArray.includes(userEmail) ? (
              <Redirect to="/onboarding" />
            ) : (
              <Redirect to="/" />
            )}
            {/* {errorArray.includes(userEmail) && } */}
          </Switch>
        </Router>
      </div>
    </Suspense>
  );
}

export default App;
